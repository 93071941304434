import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Collapse,
  TextField,
  Button,
  Box,
  Alert,
  IconButton,
} from "@mui/material";
import { ExpandMore, ExpandLess, CheckCircle } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import { fetchEstate } from "../../redux/estateSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { Controller, set, SubmitHandler, useForm } from "react-hook-form";
import {
  Electricity,
  ElectricityHeat,
  CoolingEnergy,
  CoolingFlow,
  HeatingEnergy,
  HeatingFlow,
  Time,
  Oil,
  Temperature,
  Water,
  Efficiency,
} from "../../icons/gomorron";
import {
  Domain,
  House,
  PinDrop,
  Speed,
  ChevronRight,
  Check,
  Recycling,
} from "@mui/icons-material";
import {
  clearPendingReadings,
  getCounterValueExists,
  getCounterWarnings,
  saveCounterValue,
} from "../../redux/counterSlice";
import {
  IFormInput,
  PendingReading,
  Reading,
  Verification,
} from "../../interfaces/interfaces";
import { ReadingType } from "../../enums/ReadingType";
import { PayloadAction } from "@reduxjs/toolkit";
import EstateViewCounterTreeIcon from "./EstateViewCounterTreeIcon";
import EstateViewMeasurePointTreeIcon from "./EstateViewMeasurePointTreeIcon";
import ControlledNumericTextField from "../ControlledNumericTextField";
const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    marginTop: "2rem",
  },
  logo: {
    width: "150px",
    marginBottom: "1rem",
  },
  listGroup: {
    width: "100%",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
  },
  multiValueWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  errorAir: {
    borderColor: "red",
  },
  childListItem: {
    paddingLeft: "1rem",
    borderLeft: "1px solid #ddd",
    marginBottom: "0.5rem",
  },
  grandChildListItem: {
    paddingLeft: "0.5rem",
    paddingBottom: "0.5rem",
    paddingRight: "0.5rem",
    border: "1px solid #ddd",
    marginBottom: "0.5rem",
  },
  errorTextField: {
    backgroundColor: "#ffcccc",
  },
  warningTextField: {
    backgroundColor: "#ffb26e",
  },
});

interface Counter {
  id: string;
  name: string;
  counterNumbers: string[];
  isHeatExchanger: boolean;
  lastReading: number;
  lastReadingDate: string;
  hasReadingThisPeriod: boolean;
  estimatedReading: number;
  tags: string[];
}

interface MeasurePoint {
  id: string;
  name: string;
  measurePointNumbers: string[];
  type: string;
  allCountersHaveReadings: boolean;
  counters: Counter[];
}

interface Address {
  id: string;
  name: string;
  allMeasurePointsHaveReadings: boolean;
  measurePoints: MeasurePoint[];
}

interface Estate {
  addresses: Address[];
}


const EstateView: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { estateId } = useParams<{ estateId: string }>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const estate = useSelector(
    (state: RootState) => state.estates.fetchEstate.data
  );

  const [airValues, setAirValues] = useState<{ [key: string]: number }>({});
  const [efficiency, setEfficiency] = useState<{ [key: string]: string }>({});
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { register, handleSubmit, control } = useForm<IFormInput>();
  const saveCounterValueResult = useSelector(
    (state: RootState) => state.counter.saveCounterValue
  );

  useEffect(() => {
    dispatch(clearPendingReadings());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchEstate(Number(estateId)));
  }, [dispatch, estateId]);

  useEffect(() => {
    const calculateEfficiency = () => {
      const newEfficiency: { [key: string]: string } = {};
      let shouldDisableSubmit = false;

      Object.keys(airValues).forEach((key) => {
        const id = parseInt(key.split("_").pop() || "");

        const fromAir = airValues[`FROMAIR_${id}`] || 0;
        const offAir = airValues[`OFFAIR_${id}`] || 0;
        const outAir = airValues[`OUTAIR_${id}`] || 0;

        if (fromAir && offAir && outAir) {
          let efficiencyValue = ((fromAir - offAir) / (fromAir - outAir)) * 100;
          if (isNaN(efficiencyValue) || !isFinite(efficiencyValue)) {
            efficiencyValue = 0;
          }
          newEfficiency[id] = `${efficiencyValue.toFixed(2)}%`;

          if (fromAir - outAir === 0) {
            shouldDisableSubmit = true;
          }
        }
      });

      setEfficiency(newEfficiency);
      setDisableSubmit(shouldDisableSubmit);
    };

    calculateEfficiency();
  }, [airValues]);

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    let validData: Reading[] = [];
    Object.keys(data).forEach((key) => {
      const id = parseInt(key.split("_").pop() || "");
      const valueType = key.split("_")[0];
      const input = data[key];
      // if data[key].value is not undefined, null or empty add to validData
      if (input) {
        const existing = validData.find((item) => item.id === id);
        if (existing) {
          existing.values[valueType] = input;
        } else {
          validData.push({
            id,
            values: { [valueType]: input },
            type:
              valueType === "val" ? ReadingType.default : ReadingType.heating,
          });
        }
      }
    });
    // Check if the counter has any warnings and return them, and if it does, output to console log
    const payloadAction: any = await dispatch(getCounterWarnings(validData));
    let hasWarnings: Boolean = false;
    if (payloadAction.meta.requestStatus === "fulfilled") {
      hasWarnings = payloadAction.payload.some(
        (pendingReading: PendingReading) =>
          pendingReading.warnings.filter((x: Verification) => x.warning)
            .length > 0
      );

      let exists: Boolean = false;
      const existsPayloadAction: any = await dispatch(
        getCounterValueExists(validData)
      );
      if (existsPayloadAction.meta.requestStatus === "fulfilled") {
        exists = existsPayloadAction.payload.some(
          (item: any) => item.alreadyExists
        );

        if (hasWarnings || exists) {
          // Redirect to WarningPage route
          navigate("/warnings");
        } else {
          const result = await dispatch(saveCounterValue(validData));
          if ((await result.meta.requestStatus) === "fulfilled") {
            navigate("/result");
          }
        }
      }
    }
  };

  const handleAirValueChange = (id: number, field: string, value: number) => {
    setAirValues((prevValues) => ({
      ...prevValues,
      [`${field}_${id}`]: value,
    }));
  };



  return (
    <div>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        {error && <Alert severity="error">{error}</Alert>}
        {estate ? (
          <Box sx={{ maxHeight: "60vh", overflowY: "auto", minWidth: "200px" }}>
            <SimpleTreeView>
              {estate.addresses.map((address) => (
                <TreeItem
                  key={`address-${address.id}`}
                  itemId={`address-${address.id}`}
                  label={
                    <div className={classes.listItem}>
                      <Typography>{address.name}</Typography>
                      {address.allMeasurePointsHaveReadings && (
                        <CheckCircle color="success" />
                      )}
                    </div>
                  }
                >
                  {address.measurePoints.map((measurePoint) => (
                    <TreeItem
                      key={`measurepoint-${measurePoint.id}`}
                      itemId={`measurepoint-${measurePoint.id}`}
                      label={
                        <div
                          className={`${classes.listItem} ${classes.childListItem}`}
                        >
                          <EstateViewMeasurePointTreeIcon
                            measurePoint={measurePoint}
                            defaultValue=""
                          />
                          <Typography>
                            {measurePoint.name ||
                              measurePoint.measurePointNumbers.join(", ")}{" "}
                            {measurePoint.type ? `(${measurePoint.type})` : ""}
                          </Typography>
                          {measurePoint.allCountersHaveReadings && (
                            <CheckCircle color="success" />
                          )}
                        </div>
                      }
                    >
                      {measurePoint.counters.map((counter) => (
                        <TreeItem
                          key={`counter-${counter.id}`}
                          itemId={`counter-${counter.id}`}
                          label={
                            <div
                              className={`${classes.listItem} ${classes.grandChildListItem}`}
                            >
                              <div className={classes.multiValueWrapper}>
                                <div
                                  style={{
                                    padding: "0.1rem",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <EstateViewCounterTreeIcon
                                    counter={counter}
                                    defaultValue=""
                                  />
                                  <Typography component={"span"}>
                                    {counter.name.length > 0 ||
                                    counter.counterNumbers.length > 0 ? (
                                      <div>
                                        {counter.name} (
                                        {counter.counterNumbers.join(", ")})
                                      </div>
                                    ) : (
                                      <div>SAVEUP-ID: {counter.id}</div>
                                    )}
                                  </Typography>
                                </div>
                                {counter.isHeatExchanger ? (
                                  <div className={classes.multiValueWrapper}>
                                    <ControlledNumericTextField name="FROMAIR" nodeId={counter.id} control={control} label="Från-luft" className="reading-number multi-value-input from-air" handleAirValueChange={handleAirValueChange} />
                                    <ControlledNumericTextField name="OFFAIR" nodeId={counter.id} control={control} label="Av-luft" className="reading-number multi-value-input off-air" handleAirValueChange={handleAirValueChange} />
                                    <ControlledNumericTextField name="OUTAIR" nodeId={counter.id} control={control} label="Ute-luft" className={`reading-number multi-value-input out-air ${airValues[`OUTAIR_${counter.id}`] > 5 ? classes.warningTextField: ""}`} handleAirValueChange={handleAirValueChange} />
                                    <TextField
                                      label="Verkningsgrad"
                                      type="text"
                                      className={`reading-number ${
                                        airValues[`FROMAIR_${counter.id}`] -
                                          airValues[`OUTAIR_${counter.id}`] ===
                                        0
                                          ? classes.errorTextField
                                          : ""
                                      }`}
                                      style={{ maxWidth: 110 }}
                                      name={`efficiency_${counter.id}`}
                                      id={`efficiency_${counter.id}`}
                                      value={efficiency[counter.id] || "0%"}
                                      InputProps={{ readOnly: true }}
                                      onClick={(e) => e.stopPropagation()}
                                      onFocus={(e) => e.stopPropagation()}
                                      onKeyDown={(e) => e.stopPropagation()}
                                      data-type="efficiency"
                                      data-id={counter.id}
                                    />
                                    <input
                                      type="hidden"
                                      id={`isHeatExchanger_${counter.id}`}
                                      name={`isHeatExchanger_${counter.id}`}
                                      value={counter.isHeatExchanger.toString()}
                                    />
                                  </div>
                                ) : (
                                  <ControlledNumericTextField name="" nodeId={counter.id} control={control} label="" className="form-control reading-number" />
                                )}
                                <div style={{ padding: "0.1rem" }}>
                                  {counter.lastReading > 0 && (
                                    <Typography
                                      fontSize={"small"}
                                      variant="subtitle2"
                                    >
                                      Senast: {counter.lastReading}
                                      {counter.lastReadingDate &&
                                        ` ${new Date(
                                          counter.lastReadingDate
                                        ).toLocaleString()}`}
                                      {counter.hasReadingThisPeriod && (
                                        <CheckCircle color="success" />
                                      )}
                                    </Typography>
                                  )}
                                  {counter.estimatedReading > 0 && (
                                    <Typography
                                      fontSize={"small"}
                                      variant="subtitle2"
                                    >
                                      Beräknat: {counter.estimatedReading}
                                    </Typography>
                                  )}
                                  {airValues[`OUTAIR_${counter.id}`] > 5 ? (
                                    <div style={{ marginTop: "8px" }}>
                                      <Alert severity="warning">
                                        Vid avläsning vid högre utetemperaturer
                                        än 5 grader blir verkningsgraden
                                        opålitlig. Försök göra mätningen vid en
                                        lägre utetemperatur om möjligt.
                                      </Alert>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          }
                        />
                      ))}
                    </TreeItem>
                  ))}
                </TreeItem>
              ))}
            </SimpleTreeView>
          </Box>
        ) : (
          <Alert severity="warning">
            Ingen fastighet vald. Tryck på tillbaka för att återgå.
          </Alert>
        )}
        {estate && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="button-primary"
            fullWidth
            disabled={loading || disableSubmit}
          >
            Spara
          </Button>
        )}
        <Button
          variant="contained"
          className="button-secondary"
          fullWidth
          onClick={() => navigate("/estates")}
        >
          Tillbaka
        </Button>
      </form>
    </div>
  );
};

export default EstateView;
